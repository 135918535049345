body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img[alt=Five9sbyAWS] { width: 550px; }
img[alt=awsDiagram] { width: 500px; }
img[alt=smartGuy] { width: 550px; }
img[alt=capTheorem] { width: 550px; }
img[alt=cloudVendors] { width: 550px; }
img[alt=ikeaWall] { width: 100%; height: 200px; }
img[alt=satisfactionMeme] { width: 200px; height: 200px; }
img[alt=CW] { width: 550px; height: 350px; }
img[alt=sat] { width: 450px; height: 350px; }
img[alt=lawyer] { width: 350px; height: 350px; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#text {
  z-index: 100;
  position: relative;
  color: black;
  font-size: 24px;
  font-weight: bold;
  top: -120px;
  height: 0px;
}

